// Loops
// Empty for now...

// Mixins

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin site-text {
    @media screen and (max-width: 1249px) {
        color: white;
        font-family: "Aquire";
        font-size: 16px;
        margin: 12px 0 0;
    }

    @media screen and (min-width: 1250px) {
        color: white;
        font-family: "Aquire";
        font-size: 42px;
        margin: 24px 0 0 0;
    }
}

// Fonts

@font-face {
    font-family: "Aquire";
    src: url("./assets/Aquire-BW0ox.otf")
}

// Keyframes

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
}

@keyframes swoop-in {
    from {
        opacity: 0;
        transform: translateX(-100%);
        transition: all 3s;
    } to {
        opacity: 1;
        transform: translateX(0);
        transition: all 3s;
    }
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

// Elements

body {
    @include flex-column();
    background-color:rgb(44, 44, 50);
    justify-content: center;
};

// Classes

.bullet-points-container {
    @include flex-column();

    @media screen and (max-width: 1249px) {
        width: 210px;
    }

    .bullet-point-container {
        @include flex-row();
        align-items: center;

        @media screen and (max-width: 1249px) {
            width: 160px;
            margin: 12px 0 0;

            .skill-name {
                margin: 8px;
                font-size: 16px;
            }
        }
    
        @media screen and (min-width: 1250px) {
            .skill-name {
                margin: 12px;
                font-size: 36px;
            }
        }
    }
}

.header-text {
    @include site-text();
    @media screen and (max-width: 1249px) {
        padding: 8px;
    }

    @media screen and (min-width: 1250px) {
        padding: 18px;
    }

    border: orange solid;
}

.job-container {
    @include flex-column();
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 0 25px;

    img {
        @media screen and (max-width: 1249px) {
            width: 100px;
            height: 100px;
            padding: 8px;
            margin: 24px 0 0;
        }
    
        @media screen and (min-width: 1250px) {
            width: 125px;
            height: 125px;
            padding: 12px;
            margin: 24px 0 0;
        }

        background-color: white;
        border-radius: 50%;
    }
}

.job-header-container {
    @include flex-row();
    align-items: center;

    h2 {
        color: white;
        text-align: center;
        margin: 8px !important;
    }

    p {
        color: white;
        text-align: center;
        margin: unset;
        font-size: large;
    }
}

.list-container {
    @media screen and (min-width: 1250px) {
        width: 1000px;
    }

    li {
        color: white;
        margin: 8px;
        font-size: large;
    }
}

.project-link {
    color: orange;
}

.project-link:hover {
    color: white;
}

.project-link-container {
    @include flex-row();
    gap: 8px;
    color: orange;
    p {
        margin: unset;
    }
}

.skill-tag-container {
    @include flex-row();
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 1249px) {
        margin: 12px 0;
    }

    @media screen and (min-width: 1250px) {
        margin: 24px 0 12px;
    }
    
    p {
        color: white;
        margin: 2px !important;
        border: 1px orange solid;
        border-radius: 8px;
        padding: 4px 8px;
        text-align: center;
        font-size: large;
    }
}

.job-title-container {
    @include flex-column();
    align-items: center;
}

.project-name {
    font-family: 'Aquire';
    color: white;

    @media screen and (max-width: 1249px) {
        font-size: 24px;
        margin: 12px 0 0;
    }

    @media screen and (min-width: 1250px) {
        font-size: 42px;
        margin: 24px 0 0;
    }
}

.school-container {
    @include flex-column();
    align-items: center;
    width: 300px;

    @media screen and (max-width: 1249px) {
        margin: 12px 0;

        img {
            width: 100px;
            margin: 12px 0;
            border: white solid; 
            padding: 8px;
        }

        p {
            margin: unset;
            font-size: large;
            text-align: center;
            color: white;
            word-wrap: none;
            font-family: "Trebuchet MS";
        }
    }

    @media screen and (min-width: 1250px) {
        margin: 0 24px;

        img {
            width: 200px;
            margin: 0 0 12px 0;
            border: white solid; 
            padding: 8px;
        }

        p {
            margin: unset;
            font-size: 20px;
            text-align: center;
            color: white;
            word-wrap: none;
            font-family: "Trebuchet MS";
        }
    }
}

.section-container {
    height: fit-content;
    width: 90%;

    @media screen and (max-width: 1249px) {
        margin: 0 0 24px;
        border-top: solid orange;
    }

    @media screen and (min-width: 1250px) {
        margin: 48px 0;
    }
}

.section-label {
    @include site-text();
    height: fit-content;
    width: fit-content;

    @media screen and (max-width: 1249px) {
        margin: 24px 0 0;
        border: orange solid;
        padding: 8px;
        text-align: center;
    }

    @media screen and (min-width: 1250px) {
        margin: 0 0 24px;
        border: 2px solid orange;
        padding: 18px;
    }
}

.skill-category-container {
    @media screen and (max-width: 1249px) {
        @include flex-row();
        align-items: center;
        justify-content: center;
        margin: 12px 0;
        width: 210px;
    }

    @media screen and (min-width: 1250px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0 24px;
        padding: 12px;
    }
    
    h1 {
        @include site-text();
        margin: unset;
        white-space: nowrap;
    }
}

.skill-icon {
    @media screen and (max-width: 1249px) {
        width: 24px;
        height: 24px;
        animation: swoop-in 3.5s;
    }

    @media screen and (min-width: 1250px) {
        width: 48px;
        width: 48px;
        animation: swoop-in 3.5s;
        margin: 8px;
    }
}

.typing-effect-text {
    @include site-text();
    overflow: hidden;
    border-right: .1em solid orange;
    white-space: nowrap;
    padding: 0 12px 0 0;
    margin: 24px auto;
    animation:
        typing 3.5s steps(30, end),
        blink-caret .75s step-end infinite,
}

// IDs 

#education-container {
    @include flex-column;
    align-items: center;
    margin: 0 0 150px 0;
}

#experience-container {
    @include flex-column();
    align-items: center;
}

#footer-container {
    @include flex-column();
    align-items: center;
    background-color: rgb(44, 44, 50);
    border-top: 1px solid orange;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;

    @media screen and (min-width: 1249px) {
        height: 75px;
        margin: 12px 0 0;
    }

    @media screen and (min-width: 1250px) {
        height: 100px;
        margin: 24px 0 0;
    }

    #resume-link {
        color: white;
        font-family: 'Trebuchet MS';
        
        @media screen and (max-width: 1249px) {
            font-size: medium;
        }
    
        @media screen and (min-width: 1250px) {
            font-size: large;
        }    
        
        text-decoration: none;
        border: 1px solid orange;
        padding: 8px;
    }
}

#footer-icons-container {
    @include flex-row();
    margin: 24px 0;
    align-items: center;
    justify-content: space-around;
    
    @media screen and (max-width: 1249px) {
        width: 380px;
        .footer-icon {
            width: 36px;
        }
    }

    @media screen and (min-width: 1250px) {
        width: 500px;
        .footer-icon {
            width: 50px;
        }
    }
}

#projects-container {
    @include flex-column();
    align-items: center;
    @media screen and (min-width: 1250px) {
        width: fit-content;
    }
}

#resume-header-container {  
    @include flex-column();
    align-items: center;
    
    @media screen and (max-width: 1249px) {
        margin: 48px 0 24px;
    }

    @media screen and (min-width: 1250px) {
        margin: 100px 0 0;
    }
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#schools-container {
    @media screen and (max-width: 1249px) {
        display: flex;
        flex-direction: column-reverse;    
    }

    @media screen and (min-width: 1250px) {
        @include flex-row();
        align-items: center;
        justify-content: center;
        margin: 24px 0 0;
    }
}

#skill-column-container {
    @media screen and (max-width: 1249px) {
        @include flex-column();
    }

    @media screen and (min-width: 1250px) {
        @include flex-row();
    }

}

#skill-section-container {
    @include flex-row();
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1249px) {
        .skill-category-text {
            border: solid orange;
            padding: 8px;
        }

        #skills-label {
            display: none;
        }
    }

    #skills-label {
        transform: rotate(90deg);
    }

    .skill-category-text {
        transform: rotate(90deg);
    }
}

#skills-divider {
    @media screen and (max-width: 1249px) {
        align-self: center;
        width: 200px;
        height: 2px;
        border-top: solid orange;
        margin: 12px 0 0;
    }

    @media screen and (min-width: 1250px) {
        display: none;
    }
}

#project-back-button-container {
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1249px) {
        height: 110px;
        width: 100px;
        position: absolute;
        bottom: 50px;
    }
}

#project-next-button-container {
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1249px) {
        height: 110px;
        width: 100px;
        position: absolute;
        right: 0px;
        bottom: 50px;
    }
}

.gallery-button {
    z-index: 999;
    background-color: unset;
    font-family: 'Aquire';
    font-size: x-large;
    transform: rotate(90deg);
    padding: 8px 12px;

    @media screen and (max-width: 1249px) {
        position: absolute;
        color: rgb(44, 44, 50);
        border: 2px solid orange;
    }

    @media screen and (min-width: 1250px) {
        color: white;
        border: 1px solid orange;
    }
}

.gallery-button:disabled {
    @media screen and (max-width: 1249px) {
        color: rgb(44, 44, 50);
        border: 2px solid rgb(44, 44, 50);
    }

    @media screen and (min-width: 1250px) {
        color: grey;
        border: 1px solid grey;
    }
}

.project-screenshot {
    @media screen and (max-width: 1249px) {
        margin: 12px 0 0 0;
        width: 400px;
        z-index: 900;
    }

    @media screen and (min-width: 1250px) {
        margin: 24px 0 0 0;
        width: 1100px;
    }
}

.project-container {
    @include flex-row();
    align-items: center;
    position: relative;
}