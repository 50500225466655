@font-face {
  font-family: "Aquire";
  src: url("./assets/Aquire-BW0ox.otf");
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
@keyframes swoop-in {
  from {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 3s;
  }
  to {
    opacity: 1;
    transform: translateX(0);
    transition: all 3s;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
body {
  display: flex;
  flex-direction: column;
  background-color: rgb(44, 44, 50);
  justify-content: center;
}

.bullet-points-container {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1249px) {
  .bullet-points-container {
    width: 210px;
  }
}
.bullet-points-container .bullet-point-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1249px) {
  .bullet-points-container .bullet-point-container {
    width: 160px;
    margin: 12px 0 0;
  }
  .bullet-points-container .bullet-point-container .skill-name {
    margin: 8px;
    font-size: 16px;
  }
}
@media screen and (min-width: 1250px) {
  .bullet-points-container .bullet-point-container .skill-name {
    margin: 12px;
    font-size: 36px;
  }
}

.header-text {
  border: orange solid;
}
@media screen and (max-width: 1249px) {
  .header-text {
    color: white;
    font-family: "Aquire";
    font-size: 16px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .header-text {
    color: white;
    font-family: "Aquire";
    font-size: 42px;
    margin: 24px 0 0 0;
  }
}
@media screen and (max-width: 1249px) {
  .header-text {
    padding: 8px;
  }
}
@media screen and (min-width: 1250px) {
  .header-text {
    padding: 18px;
  }
}

.job-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 0 25px;
}
.job-container img {
  background-color: white;
  border-radius: 50%;
}
@media screen and (max-width: 1249px) {
  .job-container img {
    width: 100px;
    height: 100px;
    padding: 8px;
    margin: 24px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .job-container img {
    width: 125px;
    height: 125px;
    padding: 12px;
    margin: 24px 0 0;
  }
}

.job-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.job-header-container h2 {
  color: white;
  text-align: center;
  margin: 8px !important;
}
.job-header-container p {
  color: white;
  text-align: center;
  margin: unset;
  font-size: large;
}

@media screen and (min-width: 1250px) {
  .list-container {
    width: 1000px;
  }
}
.list-container li {
  color: white;
  margin: 8px;
  font-size: large;
}

.project-link {
  color: orange;
}

.project-link:hover {
  color: white;
}

.project-link-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: orange;
}
.project-link-container p {
  margin: unset;
}

.skill-tag-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
@media screen and (max-width: 1249px) {
  .skill-tag-container {
    margin: 12px 0;
  }
}
@media screen and (min-width: 1250px) {
  .skill-tag-container {
    margin: 24px 0 12px;
  }
}
.skill-tag-container p {
  color: white;
  margin: 2px !important;
  border: 1px orange solid;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
  font-size: large;
}

.job-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-name {
  font-family: "Aquire";
  color: white;
}
@media screen and (max-width: 1249px) {
  .project-name {
    font-size: 24px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .project-name {
    font-size: 42px;
    margin: 24px 0 0;
  }
}

.school-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
}
@media screen and (max-width: 1249px) {
  .school-container {
    margin: 12px 0;
  }
  .school-container img {
    width: 100px;
    margin: 12px 0;
    border: white solid;
    padding: 8px;
  }
  .school-container p {
    margin: unset;
    font-size: large;
    text-align: center;
    color: white;
    word-wrap: none;
    font-family: "Trebuchet MS";
  }
}
@media screen and (min-width: 1250px) {
  .school-container {
    margin: 0 24px;
  }
  .school-container img {
    width: 200px;
    margin: 0 0 12px 0;
    border: white solid;
    padding: 8px;
  }
  .school-container p {
    margin: unset;
    font-size: 20px;
    text-align: center;
    color: white;
    word-wrap: none;
    font-family: "Trebuchet MS";
  }
}

.section-container {
  height: fit-content;
  width: 90%;
}
@media screen and (max-width: 1249px) {
  .section-container {
    margin: 0 0 24px;
    border-top: solid orange;
  }
}
@media screen and (min-width: 1250px) {
  .section-container {
    margin: 48px 0;
  }
}

.section-label {
  height: fit-content;
  width: fit-content;
}
@media screen and (max-width: 1249px) {
  .section-label {
    color: white;
    font-family: "Aquire";
    font-size: 16px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .section-label {
    color: white;
    font-family: "Aquire";
    font-size: 42px;
    margin: 24px 0 0 0;
  }
}
@media screen and (max-width: 1249px) {
  .section-label {
    margin: 24px 0 0;
    border: orange solid;
    padding: 8px;
    text-align: center;
  }
}
@media screen and (min-width: 1250px) {
  .section-label {
    margin: 0 0 24px;
    border: 2px solid orange;
    padding: 18px;
  }
}

@media screen and (max-width: 1249px) {
  .skill-category-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 12px 0;
    width: 210px;
  }
}
@media screen and (min-width: 1250px) {
  .skill-category-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 24px;
    padding: 12px;
  }
}
.skill-category-container h1 {
  margin: unset;
  white-space: nowrap;
}
@media screen and (max-width: 1249px) {
  .skill-category-container h1 {
    color: white;
    font-family: "Aquire";
    font-size: 16px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .skill-category-container h1 {
    color: white;
    font-family: "Aquire";
    font-size: 42px;
    margin: 24px 0 0 0;
  }
}

@media screen and (max-width: 1249px) {
  .skill-icon {
    width: 24px;
    height: 24px;
    animation: swoop-in 3.5s;
  }
}
@media screen and (min-width: 1250px) {
  .skill-icon {
    width: 48px;
    width: 48px;
    animation: swoop-in 3.5s;
    margin: 8px;
  }
}

.typing-effect-text {
  overflow: hidden;
  border-right: 0.1em solid orange;
  white-space: nowrap;
  padding: 0 12px 0 0;
  margin: 24px auto;
  animation: typing 3.5s steps(30, end), blink-caret 0.75s step-end infinite;
}
@media screen and (max-width: 1249px) {
  .typing-effect-text {
    color: white;
    font-family: "Aquire";
    font-size: 16px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  .typing-effect-text {
    color: white;
    font-family: "Aquire";
    font-size: 42px;
    margin: 24px 0 0 0;
  }
}

#education-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 150px 0;
}

#experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(44, 44, 50);
  border-top: 1px solid orange;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100%;
}
@media screen and (min-width: 1249px) {
  #footer-container {
    height: 75px;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  #footer-container {
    height: 100px;
    margin: 24px 0 0;
  }
}
#footer-container #resume-link {
  color: white;
  font-family: "Trebuchet MS";
  text-decoration: none;
  border: 1px solid orange;
  padding: 8px;
}
@media screen and (max-width: 1249px) {
  #footer-container #resume-link {
    font-size: medium;
  }
}
@media screen and (min-width: 1250px) {
  #footer-container #resume-link {
    font-size: large;
  }
}

#footer-icons-container {
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 1249px) {
  #footer-icons-container {
    width: 380px;
  }
  #footer-icons-container .footer-icon {
    width: 36px;
  }
}
@media screen and (min-width: 1250px) {
  #footer-icons-container {
    width: 500px;
  }
  #footer-icons-container .footer-icon {
    width: 50px;
  }
}

#projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 1250px) {
  #projects-container {
    width: fit-content;
  }
}

#resume-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1249px) {
  #resume-header-container {
    margin: 48px 0 24px;
  }
}
@media screen and (min-width: 1250px) {
  #resume-header-container {
    margin: 100px 0 0;
  }
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1249px) {
  #schools-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 1250px) {
  #schools-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 24px 0 0;
  }
}

@media screen and (max-width: 1249px) {
  #skill-column-container {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 1250px) {
  #skill-column-container {
    display: flex;
    flex-direction: row;
  }
}

#skill-section-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1249px) {
  #skill-section-container .skill-category-text {
    border: solid orange;
    padding: 8px;
  }
  #skill-section-container #skills-label {
    display: none;
  }
}
#skill-section-container #skills-label {
  transform: rotate(90deg);
}
#skill-section-container .skill-category-text {
  transform: rotate(90deg);
}

@media screen and (max-width: 1249px) {
  #skills-divider {
    align-self: center;
    width: 200px;
    height: 2px;
    border-top: solid orange;
    margin: 12px 0 0;
  }
}
@media screen and (min-width: 1250px) {
  #skills-divider {
    display: none;
  }
}

#project-back-button-container {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1249px) {
  #project-back-button-container {
    height: 110px;
    width: 100px;
    position: absolute;
    bottom: 50px;
  }
}

#project-next-button-container {
  display: flex;
  z-index: 999;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1249px) {
  #project-next-button-container {
    height: 110px;
    width: 100px;
    position: absolute;
    right: 0px;
    bottom: 50px;
  }
}

.gallery-button {
  z-index: 999;
  background-color: unset;
  font-family: "Aquire";
  font-size: x-large;
  transform: rotate(90deg);
  padding: 8px 12px;
}
@media screen and (max-width: 1249px) {
  .gallery-button {
    position: absolute;
    color: rgb(44, 44, 50);
    border: 2px solid orange;
  }
}
@media screen and (min-width: 1250px) {
  .gallery-button {
    color: white;
    border: 1px solid orange;
  }
}

@media screen and (max-width: 1249px) {
  .gallery-button:disabled {
    color: rgb(44, 44, 50);
    border: 2px solid rgb(44, 44, 50);
  }
}
@media screen and (min-width: 1250px) {
  .gallery-button:disabled {
    color: grey;
    border: 1px solid grey;
  }
}

@media screen and (max-width: 1249px) {
  .project-screenshot {
    margin: 12px 0 0 0;
    width: 400px;
    z-index: 900;
  }
}
@media screen and (min-width: 1250px) {
  .project-screenshot {
    margin: 24px 0 0 0;
    width: 1100px;
  }
}

.project-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

